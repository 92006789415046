<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: calc(100vh - 34px);">
      <el-form
        ref="contentForm"
        class="max-vh"
        :rules="rules"
        :model="datastore"
        label-position="top"
        size="large"
        :disabled="isArchivedStatus"
      >
        <el-row type="flex" style="padding-top: 20px">
          <el-col :span="6"></el-col>
          <el-col :span="18">
            <el-row type="flex">
              <h2
                class="custom-datastore-icon inf-icon inf-icon-datastore"
              ></h2>
              <page-header
                :title="__('Datastore')"
                :content-id="this.id"
                style="margin-top: 33px;"
              />
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="12">
            <el-form-item prop="display_name" :label="__('Name')">
              <el-input
                :value="datastore.display_name"
                @input="$set(datastore, 'display_name', $event)"
                v-loading="isChecking"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="12">
            <el-form-item prop="web_hook_url" :label="__('Webhook URL')">
              <el-input
                :value="datastore.web_hook_url"
                @input="$set(datastore, 'web_hook_url', $event)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="12">
            <el-form-item
              prop="language_id"
              :label="__('Transcription language')"
            >
              <el-select
                style="width: 100%"
                :value="datastore.language_id"
                @input="$set(datastore, 'language_id', $event)"
                v-loading="asrLanguagesLoading"
                default-first-option
                :no-data-text="asrNotProvisionedErrorMessage"
              >
                <el-option
                  v-for="(item, index) in asrLanguages"
                  :label="item.language_name"
                  :value="item.language_id"
                  :key="index"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="12">
            <el-form-item
              prop="data_retention"
              :label="__('Data retention')"
              required
            >
              <el-select
                style="width: 100%"
                :value="datastore.data_retention"
                @input="$set(datastore, 'data_retention', $event)"
                default-first-option
              >
                <el-option
                  v-for="item in data_retention_options"
                  :label="item"
                  :value="item.toLowerCase()"
                  :key="item.toLowerCase()"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="12">
            <el-form-item
              prop="collection_structure"
              :label="__('Structure')"
              required
            >
              <div style="max-width: 1100px; margin-bottom: 20px;">
                <el-table
                  :data="dummy_collection_structure"
                  style="width:100%; left: 50%"
                  id="container"
                  class="container"
                  ref="container"
                  row-class-name="no-hover"
                  header-row-class-name="custom-table"
                  :highlight-current-row="false"
                >
                  <el-table-column
                    v-for="(column, index) in datastore.collection_structure"
                    width="200px"
                    style="padding: 10px;"
                    :key="index"
                  >
                    <template slot="header" slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top"
                        :disabled="ifColumnEditable(index)"
                      >
                        <div slot="content" style="white-space: pre-wrap;">
                          {{ __("This column name isn't editable") }}
                        </div>
                        <el-input
                          size="medium"
                          :placeholder="__('Column name')"
                          v-model="column.col_name"
                          :ref="'columninput_' + index"
                          :id="'columninput_' + index"
                          :disabled="!ifColumnEditable(index)"
                          v-permit="allowedRegex"
                          :maxlength="maxLength"
                          :tabindex="handleTabIndex(scope.$index.toString())"
                        >
                          <el-button
                            v-show="isSystemLogDataStore"
                            slot="suffix"
                            type="danger"
                            icon="el-icon-close"
                            class="column-delete-btn"
                            :disabled="!ifColumnEditable(index)"
                            @click="handleDeleteDataStoreColumns(scope.$index)"
                            circle
                          ></el-button>
                        </el-input>
                        <div>
                          <div style="display: flex">
                            <p
                              class="custom-datastore-mini-header"
                              style="color: #181F29; padding-right: 5px"
                            >
                              {{ __("Column name") }}
                            </p>

                            <el-tooltip
                              effect="dark"
                              :content="tooltipContent"
                              placement="right"
                              v-if="index === 0"
                            >
                              <img
                                :src="require('@/assets/icons/info_icon.svg')"
                              />
                            </el-tooltip>
                          </div>
                          <el-input
                            size="medium"
                            :placeholder="__('Column name')"
                            v-model="column.col_name"
                            :ref="'columninput_' + index"
                            :id="'columninput_' + index"
                            :disabled="!ifColumnEditable(index)"
                            v-permit="allowedRegex"
                            :maxlength="maxLength"
                            :tabindex="handleTabIndex(scope.$index.toString())"
                          >
                            <el-button
                              v-show="isSystemLogDataStore"
                              slot="suffix"
                              type="danger"
                              icon="el-icon-close"
                              class="column-delete-btn"
                              :disabled="!ifColumnEditable(index)"
                              @click="
                                handleDeleteDataStoreColumns(scope.$index)
                              "
                              circle
                            ></el-button>
                          </el-input>
                        </div>
                      </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                      <div
                        id="tableBody"
                        style="margin-bottom: 20px;display: flex; flex-direction: column; height: 100px"
                      >
                        <p class="custom-datastore-mini-header">
                          {{ __("Data Type") }}
                        </p>
                        <el-select
                          v-model="column.type"
                          :key="scope.$index"
                          :disabled="!ifColumnEditable(index)"
                          @change="changeType(index)"
                          default-first-option
                        >
                          <el-option
                            v-for="item in dataTypeOptions"
                            :label="item"
                            :value="item.toLowerCase()"
                            :key="item.toLowerCase()"
                          />
                        </el-select>
                        <el-checkbox
                          v-if="column.type === 'audio'"
                          v-model="column.transcription"
                          style="padding-left: 3px; padding-top: 5px"
                          >{{ __("Transcribe audio") }}
                        </el-checkbox>
                        <el-checkbox
                          v-if="column.type !== 'audio'"
                          v-model="column.secure"
                          style="padding-left: 3px; padding-top: 5px"
                          :disabled="!ifColumnEditable(index)"
                          >{{ __("Secure") }}
                        </el-checkbox>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    align="right"
                    style="margin-left: 80px;"
                    :show-overflow-tooltip="true"
                    class-name="no-hover"
                    v-show="isSystemLogDataStore"
                  >
                    <template slot="header">
                      <i
                        v-show="isSystemLogDataStore"
                        class="custom-icon-add el-icon-plus"
                        @click="handleAddDataStoreColumns"
                      ></i>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <div style="display: flex;margin-bottom: 25px">
              <save-button
                type="primary"
                :disabled="
                  contentNotChanged || !can('content.data-stores.write')
                "
                @click="submitForm()"
                class="submitBtn"
                :loading="loading"
                v-show="isSystemLogDataStore"
                :primaryKey="id"
                variant="CreateUpdate"
              />
              <el-button @click="$emit('cancel')" class="cancelBtn"
                >{{ __("Cancel") }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapActions, mapState } from "vuex";
import { checkUniqueName } from "@/api/dataStores";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { alphaNumericLatinRegex } from "@/utils/regex";
import _ from "lodash";
import { validURL } from "@/utils/validate";

export default {
  components: {
    PageHeader,
    SaveButton
  },
  props: {
    datastore: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: false,
      default: -1
    },
    isArchivedStatus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    let validateWebhook = async (rule, value, callback) => {
      if (!!value && !validURL(value)) {
        callback(__("URL is not valid"));
      } else {
        callback();
      }
    };
    let validateMinOneColumn = async (rule, value, callback) => {
      if (this.datastore.collection_structure.length === 0) {
        callback(__("At least one column is required"));
      } else {
        _.some(this.datastore.collection_structure, function(column) {
          if (column.col_name === "") {
            callback(new Error(__("column name cannot be empty")));
          } else if (column.col_name.startsWith("_")) {
            callback(__("column name must not start with underscore"));
          }
        });
        callback();
      }
    };
    let validateDSName = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id !== -1 && value !== this.originalDSName) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Datastore name already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Datastore already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };
    return {
      rules: {
        display_name: [
          {
            required: true,
            trigger: "change",
            message: __("Name is required")
          },
          { validator: validateDSName, trigger: "blur" }
        ],
        web_hook_url: [
          {
            validator: validateWebhook,
            trigger: "blur",
            message: __("Url is invalid")
          }
        ],
        collection_structure: {
          required: true,
          trigger: "blur",
          validator: validateMinOneColumn
        },
        data_retention: {
          required: true,
          trigger: "blur",
          message: __("data retention is required")
        }
      },
      ttsVoiceId: "",
      isChecking: false,
      folder: "prompt_audio",
      dummy_collection_structure: [
        {
          col_name: "",
          index_type: "none",
          type: "string"
        }
      ],
      dataTypeOptions: [
        "String",
        "Integer",
        // "Array",
        "Audio",
        "Double",
        "Date",
        "DateTime",
        "Boolean"
        // "Timestamp"
      ],
      data_retention_options: [
        "One day",
        "One Week",
        "One Month",
        "Two Months",
        "Three Months",
        "Six Months",
        "One Year",
        "Keep All"
      ],
      originalDSName: "",
      currentHeaderIndex: 0,
      currentBodyIndex: 1,
      allowedRegex: alphaNumericLatinRegex,
      maxLength: 200,
      additionalValidateRoute: "data-stores",
      isContent: true,
      viewMode: "allow_read",
      datastoreContent: Object
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),
    ...mapState("dataStores", {
      loading: state => state.loading
    }),
    ifColumnEditable() {
      return column_index => {
        //  if updating datastore and the column index is less than already created columns count
        if (this.id !== -1 && column_index < this.datastore.columns_count) {
          return false;
        } else {
          return true;
        }
      };
    },
    isSystemLogDataStore() {
      if (this.id !== -1) {
        return !this.datastore.is_log;
      } else {
        return true;
      }
    },

    contentNotChanged() {
      return _.isEqual(this.datastoreContent, this.datastore);
    },
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    }),

    /**
     * Content for the ASR not provisioned error message
     * @returns {String} Translated content for the ASR not provisioned error message
     */
    asrNotProvisionedErrorMessage() {
      // eslint-disable-next-line
      return __("Transcription languages are not provisioned in your business plan");
    },
    tooltipContent() {
      // eslint-disable-next-line
      return __("Note: The first column is automatically indexed. Using a unique name/identifier will allow for faster data lookups.");
    }
  },
  async created() {
    await this.initializeLanguages();
    this.originalDSName = this.datastore.display_name;
    this.datastoreContent = _.cloneDeep(this.datastore);
  },
  methods: {
    ...mapActions("dataStores", {
      createDataStore: "createDataStore",
      updateDataStore: "updateDataStore"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
    },
    handleDeleteDataStoreColumns(index) {
      // if the already existing column is deleted decrement the existing column count
      if (this.id !== -1 && index < this.datastore.columns_count) {
        this.$set(
          this.datastore,
          "columns_count",
          this.datastore.columns_count - 1
        );
      }
      let structure = _.cloneDeep(this.datastore.collection_structure);

      structure.splice(index, 1);

      this.$set(this.datastore, "collection_structure", structure);
    },

    handleTabIndex(index) {
      // this.currentHeaderIndex = index + this.currentBodyIndex;
      // this.currentBodyIndex = this.currentHeaderIndex + 1;
      // let headerTabIndex = this.currentHeaderIndex;
      // let bodyTabIndex = this.currentBodyIndex;
      // console.log(
      //   "the header index is " +
      //     this.currentHeaderIndex.toString() +
      //     " body index is " +
      //     this.currentBodyIndex.toString()
      // );
      // return headerTabIndex.toString();
      return index.toString();
    },
    handleAddDataStoreColumns() {
      console.log(this.datastore.collection_structure.length);
      let newColumn = {};
      newColumn.col_name = "";
      newColumn.index_type = "";
      newColumn.type = "string";
      let collection = _.cloneDeep(this.datastore.collection_structure);
      collection.push(newColumn);
      this.$set(this.datastore, "collection_structure", collection);
      this.$nextTick(() => {
        let collection_struct_length = this.datastore.collection_structure
          .length;
        if (collection_struct_length >= 1) {
          this.$refs[
            "columninput_" + (this.datastore.collection_structure.length - 1)
          ][0].focus();
        } else {
          this.$refs["columninput_0"][0].focus();
        }
      });

      setTimeout(() => {
        let container = this.$el.querySelector(
          "#container > div.el-table__body-wrapper"
        );
        container.scrollLeft = container.scrollWidth;
      }, 200);
    },

    callMessage() {
      this.id === -1
        ? this.$message({
            type: "success",
            message: __("Datastore added successfully")
          })
        : this.$message({
            type: "success",
            message: __("Datastore updated successfully")
          });
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createDataStore : this.updateDataStore;
          this.$set(this.datastore, "ac_id", this.selectedAccountId);
          this.$set(this.datastore, "folder_id", this.selectedFolderId);

          process(this.datastore)
            .then(data => {
              this.callMessage();
              EventBus.$emit("list-changed", data.data);
              this.$emit("cancel");
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    changeType(index) {
      this.$set(this.datastore.collection_structure[index], "secure", false);
      this.$set(
        this.datastore.collection_structure[index],
        "transcription",
        false
      );
    }

    // openMoveToFolderDialog() {
    //   let content_ids = [this.datastore.data_store_id];
    //   if (this.multiSelectedItems.length) {
    //     content_ids = this.multiSelectedItems.map(item => {
    //       if (item && item.data_store_id) {
    //         return item.data_store_id;
    //       }
    //     });
    //   }

    //   EventBus.$emit("open-move-to-folder-modal", {
    //     content_ids: [...content_ids],
    //     content_model: "DataStore"
    //   });
    // }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/content-buttons.scss";
@import "~@/styles/button.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.column-delete-btn {
  font-size: 12px;
  padding: 4px !important;
  margin: 3px 2px 0 0;
}

.custom-icon-add {
  font-size: 15px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;

  &:hover {
    color: var(--theme-color);
  }
}

::v-deep .el-input__suffix {
  top: 4px;
}

.custom-table {
  background-color: #f5f5f8 !important;
}

.custom-datastore-mini-header {
  font-weight: bold;
  font-size: 12px;
}

.custom-datastore-header {
  font-weight: bold !important;
  font-size: 15px;
}

::v-deep .el-input--medium > .el-input__inner {
  font-weight: bold !important;
}

.custom-datastore-icon {
  height: 40px;
  width: 50px;
  background-size: cover;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 32px;
}

::v-deep .el-input__inner {
  background: white;

  &:hover {
    border-color: $content-theme-color;
  }

  &:focus {
    border-color: $content-theme-color;
  }
}

.container {
  ::v-deep .el-input__inner {
    text-align: center;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.no-hover:hover > td {
  background-color: initial !important;
  border-bottom: initial !important;
  border-color: initial !important;
}

::v-deep .el-table__body tr.hover-row > td {
  background-color: initial !important;
}

::v-deep .el-table__fixed::before,
.el-table__fixed-right::before {
  background-color: #fff;
  height: 20px;
  content: "";
  position: absolute;
  bottom: -20px;
  left: -20px;
  right: -20px;
}

::v-deep .el-table__fixed::after,
.el-table__fixed-right::after {
  background-color: #fff;
  height: 20px;
  content: "";
  position: absolute;
  bottom: -20px;
  left: -20px;
  right: -20px;
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
